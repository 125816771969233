<script setup lang="ts">
import OzIcon from '@@/library/v4/components/OzIcon.vue'
import { useSurfaceStore } from '@@/pinia/surface'
import type { Post, PostColor } from '@@/types'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

const props = defineProps<{
  post: Post
  color: PostColor
  iconName: 'play' | 'download'
}>()

const surfaceStore = useSurfaceStore()
const { colorScheme } = storeToRefs(surfaceStore)

const isLightColorScheme = computed<boolean>(() => colorScheme.value === 'light')

const buttonColorClass = computed<string>(() => {
  switch (props.color) {
    case 'red':
      return 'bg-scarlet-600'
    case 'orange':
      return isLightColorScheme.value ? 'bg-canary-600' : 'bg-tangerine-600'
    case 'green':
      return 'bg-park-600'
    case 'blue':
      return 'bg-oceanic-600'
    case 'purple':
      return 'bg-grape-600'
    default:
      return 'bg-dark-ui-200'
  }
})

const buttonHoverColorClass = computed<string>(() => {
  switch (props.color) {
    case 'red':
      return 'hover-hover:hover:bg-scarlet-500'
    case 'orange':
      return isLightColorScheme.value ? 'hover-hover:hover:bg-canary-500' : 'hover-hover:hover:bg-tangerine-500'
    case 'green':
      return 'hover-hover:hover:bg-park-500'
    case 'blue':
      return 'hover-hover:hover:bg-oceanic-500'
    case 'purple':
      return 'hover-hover:hover:bg-grape-500'
    default:
      return 'hover-hover:hover:bg-dark-ui-300'
  }
})

const buttonIconColorClass = computed<string>(() => {
  switch (props.color) {
    case 'red':
      return 'text-scarlet-200'
    case 'orange':
      return isLightColorScheme.value ? 'text-canary-200' : 'text-tangerine-200'
    case 'green':
      return 'text-park-200'
    case 'blue':
      return 'text-oceanic-200'
    case 'purple':
      return 'text-grape-200'
    default:
      return 'text-light-text-200'
  }
})
</script>

<template>
  <div
    :class="[
      'absolute',
      'flex',
      'items-center',
      'justify-center',
      'p-0.875',
      'rounded-full',
      'top-0',
      'start-0',
      'bottom-0',
      'end-0',
    ]"
  >
    <span
      :class="[
        // Shape
        'h-8',
        'w-8',
        'rounded-full',
        'pointer-events-auto',

        // Centralize button content
        'flex',
        'items-center',
        'justify-center',

        // Colors
        buttonColorClass,
        buttonHoverColorClass,
        buttonIconColorClass,
      ]"
    >
      <OzIcon :name="iconName" :size="16" />
    </span>
  </div>
</template>
